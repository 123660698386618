import React, { Component } from 'react';
import { postApiData } from '../../../../fetchApi';

class OnboardingStepTwo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            middleName: '',
            preferredFirstName: '',
            preferredLastName: '',
            dob: '',
            ssn: '',
            country: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            zip: '',
            state: '',
            gender: '',
            personalEmail: '',
            guid: this.props.guid
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        await postApiData("SAVE_PERSONAL_ONBOARDING_INFORMATION", [this.state])
        this.props.trackOnboardingStep()

    }

    render() {
        return (
            <div style={{ width: 900 }} className="onboarding-container">
                <div style={{ textAlign: 'center' }}><img style={{ width: 300 }} src="/StaticHTMLContent/images/2.png"></img></div>
                <br />
                <h2 style={{ textAlign: 'center' }}>Personal Information</h2>
                <br />
                <form className="row" onSubmit={this.handleSubmit}>
                    <div className="col-lg-5">
                        <label>
                            <b>Legal Full Name: </b>
                            <input className="form-control" type="text" value={this.props.apiData[0].fullName} readOnly />
                        </label>
                        <br />
                        <br />
                        <label>
                            Preferred First Name:
                            <input className="form-control" type="text" name="preferredFirstName" value={this.state.preferredFirstName} onChange={this.handleInputChange} />
                        </label>
                        <br /><br />
                        <label>
                            Preferred Last Name:
                            <input className="form-control"  type="text" name="preferredLastName" value={this.state.preferredLastName} onChange={this.handleInputChange} />
                        </label>
                        <br /><br />
                        <label>
                            Middle Name:
                            <input className="form-control" type="text" name="middleName" value={this.state.middleName} onChange={this.handleInputChange} />
                        </label>
                        <br /><br />
                        <label>
                            Personal Email:
                            <input className="form-control" type="text" name="personalEmail" value={this.state.personalEmail} onChange={this.handleInputChange} />
                        </label>
                        <br /><br />
                        <label>
                            Date of Birth:
                            <input className="form-control" type="date" name="dob" value={this.state.dob} onChange={this.handleInputChange} />
                        </label>
                        <br /><br />
                        <label>
                            Social Security Number (#########):
                            <input className="form-control" type="password" name="ssn" pattern="[0-9]{9}" value={this.state.ssn} onChange={this.handleInputChange} />
                        </label>
                    </div>
                    <div className="col-lg-1"></div><div className="col-lg-5">
                    <label>
                        Country:
                        <select className="form-control" name="country" value={this.state.country} onChange={this.handleInputChange}>
                            <option value="">Select a country</option>
                            <option value="USA">USA</option>
                            <option value="Canada">Canada</option>
                            <option value="Mexico">Mexico</option>
                        </select>
                    </label>
                    <br /><br />
                    <label>
                        Address Line 1:
                        <input className="form-control" type="text" name="addressLine1" value={this.state.addressLine1} onChange={this.handleInputChange} />
                    </label>
                    <br /><br />
                    <label>
                        Address Line 2:
                        <input className="form-control" type="text" name="addressLine2" value={this.state.addressLine2} onChange={this.handleInputChange} />
                    </label>
                    <br /><br />
                    <label>
                        State:
                        <input className="form-control" type="text" name="state" value={this.state.state} onChange={this.handleInputChange} />
                    </label>
                    <br /><br />
                    <label>
                        City:
                        <input className="form-control" type="text" name="city" value={this.state.city} onChange={this.handleInputChange} />
                    </label>
                    <br /><br />
                    <label>
                        Zip:
                        <input className="form-control" type="text" name="zip" value={this.state.zip} onChange={this.handleInputChange} />
                        </label>
                    </div>
                    <div style={{textAlign:'center', marginTop:20}}> <button className="btn btn-primary" type="submit">Save</button></div>
                </form>
            </div>
        )
    }
}

export default OnboardingStepTwo