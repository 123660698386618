import React, { Component } from 'react';
import { getApiData } from "../../../fetchApi"
import config from '../../../Config/config.json';


class BankAccountForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bankAccounts: [],
            selectedAccount: null,
            selectedFile: null,
            uploadedFileUrl: null,
            guid: ''
        };
    }

    componentDidMount = async () => {
        // Fetch bank accounts data from API and update state
        var apiData = await getApiData("GET_OWNER_BANK_INFO", [sessionStorage.getItem("x-params-2")])
        var keys = Object.keys(this.props.payrollOnboardingObj)
        var newOnboardingInfo = this.state
        for (var x = 0; x < keys.length; x++) {
            if (newOnboardingInfo[keys[x]] !== undefined) {
                newOnboardingInfo[keys[x]] = this.props.payrollOnboardingObj[keys[x]]
            }
        }
        this.setState({ bankAccounts: apiData.ownerBankingDetailsObj, guid: apiData.guid })
       
        
    }

    handleAccountChange = (event) => {
        this.setState({ selectedAccount: event.target.value });
    };

    handleFileChange = (event) => {
        this.setState({ selectedFile: event.target.files[0], uploadedFileUrl:null });
    };

    handleUploadFile = async () => {
        const { selectedFile } = this.state;

        // You need to implement a function to upload the file to your S3 bucket.
        // Here's an example of how to use the AWS SDK for this purpose.
        // Replace 'your_bucket_name' with your actual S3 bucket name.
        // Replace 'your_s3_upload_function' with your actual function for uploading the file.
        const AWS = require('aws-sdk');
        const s3 = new AWS.S3({
            region: config["Region"], // Replace with your desired region
            accessKeyId: config["accessKeyId"],
            secretAccessKey: config["secretAccessKey"],
        });

        const params = {
            Bucket: config["S3_BUCKET"],
            Key: this.state.guid + "/" + selectedFile.name + '_VoidedCheck',
            Body: selectedFile,
            ACL: 'private', // Optional, set permissions for the uploaded file
        };

        await s3.putObject(params, async (err, data) => {
            if (err) {
                console.error('Error uploading file:', err);
            } else {
                console.log('File uploaded successfully');

                // Generate a signed URL for the uploaded image
                const signedUrlParams = {
                    Bucket: config["S3_BUCKET"],
                    Key: params.Key,
                    Expires: 3600, // URL expiration time in seconds
                };
                const signedUrl = await s3.getSignedUrl('getObject', signedUrlParams);

                this.setState({ uploadedFileUrl: signedUrl });
            }
        });
    };

    trackPayrollStep = e => {
        var payrollOnboardInfo = this.state
        this.props.trackOnboardingStep(e.target.id, payrollOnboardInfo)
    }

    render() {
        const { bankAccounts, selectedAccount, selectedFile } = this.state;

        return (
            <div className="container">
                <div className="form-group">
                    <label>Select Bank Account:</label>
                    <select
                        className="form-control"
                        value={selectedAccount}
                        onChange={this.handleAccountChange}
                    >
                        <option value="">Select Bank Account</option>

                        {bankAccounts.length > 0 && bankAccounts.map((account) => (
                            <option key={account["id"]} value={account["id"]}>
                                {account["bankName"]} {account["accountNumber"].slice(-4) }
                            </option>
                        ))}
                    </select>
                </div>
                <br />
                <div className="form-group">
                    <label>Upload Voided Check Image:</label>
                    <input
                        type="file"
                        className="form-control-file"
                        onChange={this.handleFileChange}
                    />
                </div>

                
                {this.state.uploadedFileUrl && (
                    <div className="mt-3">
                        <label>Uploaded Voided Check Image:</label>
                        <img
                            src={this.state.uploadedFileUrl}
                            alt="Voided Check"
                            className="img-fluid"
                            style={{ maxWidth: '300px' }}
                        />
                    </div>
                )}
                <div>
                <br />
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.handleUploadFile}
                        disabled={!selectedAccount || !selectedFile}
                    >
                        Upload
                    </button>
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-2"><button id="continue" onClick={this.trackPayrollStep} className="btn btn-primary">Continue</button></div>
                    <div className="col-lg-2"><button id="back" className="btn btn-warning" onClick={this.trackPayrollStep}>Back</button></div>
                    <div className="col-lg-2"><button onClick={this.props.cancelOnboarding} className="btn btn-danger">Cancel</button></div>
                </div>
            </div>
        );
    }
}

export default BankAccountForm;
