import React, { Component } from 'react';
import MainGridComponent from '../MainGridComponent'
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import PayrollDetails from '../PayrollContent/PayrollWorkflow/PayrollDetails';
import { getApiData } from '../../fetchApi'
import PayrollOnboarding from './PayrollOnboarding'

export class Payroll extends Component {
   
     constructor(props) {
        super(props)
         this.state = {
             selectedDateEnd: "",
             selectedDateStart: "",
             selectedPayroll: 0,
             isPayrollOnboarded: 0
        }
    }

    componentDidMount = async () => {
        var apiData = await getApiData("IS_PAYROLL_ONBOARDED", [sessionStorage.getItem("x-params-2")])
        this.setState({isPayrollOnboarded: apiData.isPayrollOnboarded})
    }

    handleDateChangeStart = (date) => {
         this.setState({
             selectedDateStart: dayjs(date)
         })

    }

    handleDateChangeEnd = (date) => {
         this.setState({
            selectedDateEnd: dayjs(date)
        })
    }

    setSelectedPayroll = (payrollId) => {
        this.setState({selectedPayroll: payrollId})
    }

    backToPayroll = () => {
        this.setState({ selectedPayroll: 0 })
    }

    //get rid of this later
    createNewPayroll = async () => {
       await getApiData("CREATE_NEW_PAYROLL", [sessionStorage.getItem("x-params-2")])
    }

  render () {
      return (

          this.state.isPayrollOnboarded === 0 ? <PayrollOnboarding></PayrollOnboarding> :

              this.state.selectedPayroll > 0 ?
                  <PayrollDetails backToPayroll={this.backToPayroll} payrollId={this.state.selectedPayroll} ></ PayrollDetails>
              :
              <>
                       
                  <div className="payroll-filter-container row">
                      <div className="col-lg-3" style={{ display: 'inline-block' }} >
                          <span>Pay run type</span> <br />
                          <select className="form-control">
                              <option>All Types</option>
                          </select>
                      </div>
                      <div className="col-lg-9" style={{ display: 'inline-block' }}>
                          <span>Pay date range</span> <br />
                          <div className="date-picker-div">

                              <LocalizationProvider dateAdapter={AdapterDayjs}>

                                  <MobileDatePicker
                                      label="Start Date"
                                      inputFormat="MM/DD/YYYY"
                                      value={this.state.selectedDateStart}
                                      onChange={this.handleDateChangeStart}
                                      renderInput={(params) => <TextField {...params} />}
                                  />

                                  <MobileDatePicker
                                      label="End Date"
                                      inputFormat="MM/DD/YYYY"
                                      value={this.state.selectedDateEnd}
                                      onChange={this.handleDateChangeEnd}
                                      renderInput={(params) => <TextField {...params} />}
                                  />

                              </LocalizationProvider>

                          </div>
                      </div>
                  </div> <br />

                 
                  <MainGridComponent setSelectedPayroll={this.setSelectedPayroll} pagetype="Payroll"></MainGridComponent>
              </>
    );
  }
}
