import React, { Component } from 'react';
import CustomerBankingInfo from '../CustomerBankingInfo'
import SignatureCanvas from '../SignatureCanvas'
import { postApiData } from '../../../../fetchApi';

class OnboardingStepThree extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bankAccounts: [],
            nickname: '',
            fullName: '',
            routingNumber: '',
            accountNumber: '',
            accountType: '',
            bankName: '',
            formError: '',
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleRoutingNumberChange = async (event) => {
        const routingNumber = event.target.value;
        this.setState({
            routingNumber: routingNumber
        });
        if (routingNumber.length === 9) {
            var apiResponse = await fetch('https://www.routingnumbers.info/api/data.json?rn=' + event.target.value)
            var json = await apiResponse.json()
            this.setState({
                routingNumber: routingNumber,
                bankName: json.customer_name
            });
        }
    }

    setDistributionType = (val, accountNumber) => {
        
        var bankAccounts = this.state.bankAccounts
        var bankIndex = bankAccounts.findIndex(x => x.accountNumber === accountNumber)
        bankAccounts[bankIndex].distributionType = val
        this.setState({ bankAccounts: bankAccounts })
    }

    setDistributionAmount = (val, accountNumber) => {
        //add validation for %'s to make sure all percent splits total to 100
        var bankAccounts = this.state.bankAccounts
        var bankIndex = bankAccounts.findIndex(x => x.accountNumber === accountNumber)
        bankAccounts[bankIndex].distributionAmount = val
        this.setState({ bankAccounts: bankAccounts })
    }

    handleSubmit = (event) => {
        event.preventDefault();

        var bankAccounts = this.state.bankAccounts

        var bankAccountObj =
        {
            nickname: this.state.nickname,
            fullName: this.state.fullName,
            routingNumber: this.state.routingNumber,
            accountNumber: this.state.accountNumber,
            accountType: this.state.accountType,
            bankName: this.state.bankName,
            distributionType: "Percent",
            distribtionAmount: this.state.bankAccounts.length === 0 ? "100" : "0",
            teammemberIPAddress: window.location.host,
            guid: this.props.guid
        }
        bankAccounts.push(bankAccountObj)

        this.setState({
            bankAccounts: bankAccounts, nickname: '', fullName: '', routingNumber: '', accountNumber: '', accountType: '', bankName: ''
        });
    }

    saveBankingInfo = () => {
        postApiData("SAVE_TEAMMEMBER_BANKING_INFO", [this.state.bankAccounts])
        this.props.trackOnboardingStep()
    }


    render() {
        return (
            <>
               
                <div style={{ width: 900, margin:"0 auto" }} className="row">
                    <div style={{ textAlign: 'center' }}><img style={{ width: 300 }} src="/StaticHTMLContent/images/2.png"></img></div>
                    <br />
                    <h2 style={{ textAlign: 'center' }}>Banking Information</h2>
                    <br />
                 <div className="col-lg-4">
                 <form onSubmit={this.handleSubmit}>
                    <label>
                        Nickname (optional):
                        <input className="form-control" type="text" name="nickname" value={this.state.nickname} onChange={this.handleInputChange} />
                    </label>
                        <br /><br />
                    <label>
                        Full Name on Account:
                        <input className="form-control" type="text" name="fullName" value={this.state.fullName} onChange={this.handleInputChange} required />
                    </label>
                        <br /><br />
                    <label>
                        Routing number:
                        <input className="form-control" type="text" name="routingNumber" value={this.state.routingNumber} onChange={this.handleRoutingNumberChange} required />
                    </label>
                        {this.state.bankName && <span><b>Bank Name:</b> {this.state.bankName}</span>}
                        <br /><br />
                    <label>
                        Account number:
                        <input className="form-control" type="text" name="accountNumber" value={this.state.accountNumber} onChange={this.handleInputChange} required />
                    </label>
                        <br /><br />
                    <label>
                        Account Type:
                        <select className="form-control" name="accountType" value={this.state.accountType} onChange={this.handleInputChange} required>
                            <option value="">Select Account Type</option>
                            <option value="savings">Savings</option>
                            <option value="checking">Checking</option>
                        </select>
                    </label>
                        <br /><br />
                        <div style={{textAlign:'center'} }><button className="btn btn-primary" type="submit">Save</button></div>
                        </form>
                    </div>
                    <div className="col-lg-8">
                        {this.state.bankAccounts.length > 0 ? <CustomerBankingInfo setDistributionType={this.setDistributionType} setDistributionAmount={this.setDistributionAmount} bankAccounts={this.state.bankAccounts} ></CustomerBankingInfo> : <div style={{ textAlign: 'center' }}> <b>No banks added</b></div>}
                        
                    </div>
                    <br />
               
                    <div style={{marginTop:20}}><SignatureCanvas signatureType="_bankingSignature" guid={this.props.guid} saveBankingInfo={this.saveBankingInfo} ></SignatureCanvas></div>
                </div>
            </>
        );
    }
}

export default OnboardingStepThree;
