import React, { Component } from 'react';
import MainGridComponent from '../MainGridComponent'
import TeamMemberDetails from '../TeamMemberContent/TeamMemberDetails';
import BulkHiring from '../TeamMemberContent/BulkHiring';
import Hiring from '../TeamMemberContent/Hiring';


export class TeamMembers extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedTeamMemberId: 0,
            isHiring: false,
            isBulkHire: false
        }
    }

    setSelectedTeamMember = (teamMemberId) => {
        this.setState({ selectedTeamMemberId: teamMemberId })
    }

    backToTeamMembers = () => {
        this.setState({ selectedTeamMemberId: 0 })
    }

    setHiring = () => {
        this.setState({ isHiring: true })
    }

    setBulkHiring = () => {
        this.setState({ isBulkHire: true })
    }

    cancelHiring = () => {
        this.setState({ isHiring: false, isBulkHire: false })
    }

    render() {
        return (
           this.state.isHiring ? <Hiring cancelHiring={this.cancelHiring} ></Hiring>
                :
                this.state.isBulkHire ? <BulkHiring cancelHiring={this.cancelHiring}></BulkHiring>
                    :
            this.state.selectedTeamMemberId > 0 ? 
                <TeamMemberDetails backToTeamMembers={this.backToTeamMembers} selectedTeamMemberId={this.state.selectedTeamMemberId} ></TeamMemberDetails>
                :
                <>
                    <button onClick={this.setHiring} className="btn btn-primary">New Hire</button> &nbsp;
                    <button onClick={this.setBulkHiring} className="btn btn-primary">Bulk Hire</button>
                    <MainGridComponent setSelectedTeamMember={this.setSelectedTeamMember} pagetype="TeamMembers"></MainGridComponent>
                </>
         );
    }
}
