import React, { Component } from 'react';
import { postApiData } from '../../../../fetchApi';

class EntityComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            departments: [],
            offices: [],
            newDepartment: '',
            newOffice: {
                name: '',
                addressOne: '',
                addressTwo: '',
                phoneNumber: '',
                primaryContactEmail: ''
            },
        };
    }

    handleDepartmentInputChange = (event) => {
        this.setState({ newDepartment: event.target.value });
    };

    handleOfficeInputChange = (event) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({
            newOffice: {
                ...prevState.newOffice,
                [name]: value,
            },
        }));
    };

    handleSaveDepartment = async () => {
        var { newDepartment, departments } = this.state;
        var departmentObj = {Id: 0, DepartmentName: newDepartment, EntityId: this.props.entityId}
        var departmentId = await postApiData("SAVE_DEPARTMENT", [departmentObj])
        departmentObj.id = departmentId.id
        departments.push(departmentObj)
        this.setState({ departments: departments })
        
        
    }

    handleSaveOffice = async () => {
        var { newOffice, offices } = this.state;
        newOffice.entityId = this.props.entityId
        var officeId = await postApiData("SAVE_OFFICE", [newOffice])
        newOffice.id = officeId.id
        offices.push(newOffice)
        this.setState({ offices: offices })
    };

    handleRemoveDepartment = (departmentId) => {
        //TODO figure out deletion later
        postApiData("DELETE_DEPARTMENT", [departmentId])
        this.setState((prevState) => ({
            departments: prevState.departments.filter((dept) => dept.id !== departmentId),
        }));
    };

    handleRemoveOffice = (officeId) => {
        //TODO figure out deletion later
        postApiData("DELETE_OFFICE", [officeId])
        this.setState((prevState) => ({
            offices: prevState.offices.filter((office) => office.id !== officeId),
        }));
    };

    render() {
        const { departments, offices, newDepartment, newOffice } = this.state;

        return (
            <div style={{width:900, margin:'0 auto'}} className="row">
                <div style={{ textAlign: 'center' }}><img style={{ width: 300 }} src="/StaticHTMLContent/images/2.png"></img></div>
                <br />
                <h2 style={{ textAlign: 'center' }}>Offices & Departments</h2>
                <br />
                <div className="col-lg-5">
                   
                    {departments.length === 0 ? (
                        <p>No departments exist for this entity.</p>
                    ) : (
                        <ul style={{ listStyle: 'none' }}>
                                {departments.map((department) => (
                                <>
                                    <li key={department.id}>
                                        {department.DepartmentName} &nbsp;
                                        <button className="btn btn-danger" onClick={() => this.handleRemoveDepartment(department.id)}>X</button>
                                    </li>
                                    <br />
                                </>
                            ))}
                        </ul>
                    )}
                    <h4>Add new departments</h4>
                    <input
                        type="text"
                        className="form-control"
                        value={newDepartment}
                        onChange={this.handleDepartmentInputChange}
                        placeholder="Enter a new department"
                    />
                    
                    <div style={{ textAlign: 'center', marginTop:20 }}><button className="btn btn-primary" onClick={this.handleSaveDepartment}>Save</button></div>
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-6">
                    
                    {offices.length === 0 ? (
                        <p>No office locations exist for this entity.</p>
                    ) : (
                        <ul style={{ listStyle:'none' }}>
                                {offices.map((office) => (
                                <>
                                    <li key={office.id}>
                                        {office.name} &nbsp;
                                        <button className="btn btn-danger" onClick={() => this.handleRemoveOffice(office.id)}>X</button>
                                    </li>
                                    <br />
                                </>
                            ))}
                        </ul>
                    )}
                    <h3>Add new office</h3>
                    <div>
                        
                        <label>
                            Name: <br />
                            <input
                                className="form-control"
                                type="text"
                                name="name"
                                value={newOffice.name}
                                onChange={this.handleOfficeInputChange}
                            />
                        </label>
                    </div>
                    <div>
                        <br />
                        <label>
                            Address One: <br />
                            <input
                                type="text"
                                className="form-control"
                                name="addressOne"
                                value={newOffice.addressOne}
                                onChange={this.handleOfficeInputChange}
                            />
                        </label>
                    </div>
                    <div>
                        <br />
                        <label>
                            Address Two: <br />
                            <input
                                type="text"
                                className="form-control"
                                name="addressTwo"
                                value={newOffice.addressTwo}
                                onChange={this.handleOfficeInputChange}
                            />
                        </label>
                    </div>
                    <div>
                        <br />
                        <label>
                            Phone Number:
                            <input
                                type="text"
                                className="form-control"
                                name="phoneNumber"
                                value={newOffice.phoneNumber}
                                onChange={this.handleOfficeInputChange}
                            />
                        </label>
                     </div>
                        <br />
                      <div>
                        <label>
                            Primary Contact Email:
                            <input
                                type="text"
                                className="form-control"
                                name="primaryContactEmail"
                                value={newOffice.primaryContactEmail}
                                onChange={this.handleOfficeInputChange}
                            />
                        </label>
                     </div>
                        <br />
                        <div style={{ textAlign: 'center' }}><button className="btn btn-primary" onClick={this.handleSaveOffice}>Save</button></div>
                   
                </div>

                <div className="row" style={{ margin: '0 auto', width:250, marginTop:25 }}> <a onClick={this.props.trackOnboardingStep} style={{width:250}} className="btn btn-primary">Complete Onboarding</a></div>
            </div>
        );
    }
}

export default EntityComponent;
