import React, { Component } from 'react';
import {
    faEye,
    faEyeSlash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class CustomerBankingInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bankAccounts: this.props.bankAccounts,
            maskedData: {
                'accountNumber': true,
            },
           
        };
    }

    toggleFieldVisibility = (e) => {
        var maskedData = this.state.maskedData;
        maskedData[e.currentTarget.id] = !maskedData[e.currentTarget.id]
        this.setState({ maskedData: maskedData })
    }

    setDistributionType = (e) => {
        this.props.setDistributionType(e.target.value, e.target.name)
    }

    setDistributionAmount = (e) => {
        this.props.setDistributionAmount(e.target.value, e.target.name)
    }

    render() {
        return (
            <>
            <div className="row">
                <div className="col-lg-2"><b>Bank Name</b></div>
                <div className="col-lg-2"><b>Full Name</b></div>
                <div className="col-lg-2"><b>Routing Number</b></div>
                <div className="col-lg-2"><b>Account Number</b></div>
                <div className="col-lg-2"><b>Distribution Type</b></div>
                <div className="col-lg-2"><b>Distribution Amount</b></div>
            </div>
                {this.state.bankAccounts.map(x =>
                    <div className="row">
                        <div className="col-lg-2">{x.bankName}</div>
                        <div className="col-lg-2">{x.fullName}</div>
                        <div className="col-lg-2">{x.routingNumber}</div>
                        <div className="col-lg-2">
                        {this.state.maskedData["accountNumber"] ?
                           <><div style={{ display: 'inline-block' }} >&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&nbsp;</div><div style={{ display: 'inline-block' }}><FontAwesomeIcon id="accountNumber" onClick={this.toggleFieldVisibility} icon={faEye} /></div> </>
                            :
                           <><div style={{ display: 'inline-block' }}> {x.accountNumber} </div> <div style={{ display: 'inline-block' }}><FontAwesomeIcon id="accountNumber" onClick={this.toggleFieldVisibility} icon={faEyeSlash} /></div> </>
                        }
                        </div>
                        <div className="col-lg-2"><select className="form-control" name={x.accountNumber} onChange={this.setDistributionType} >
                            <option selected key="Percent">Percent</option>
                            <option key="Flat">Flat</option>
                        </select></div>
                        <div className="col-lg-2"><input type="number" name={x.accountNumber} className="form-control" value={x.distributionAmount} onChange={this.setDistributionAmount} id="distribtionAmount"></input></div>
                    </div>
                  )
                }
                <br />
            </>
           
        );
    }
}

export default CustomerBankingInfo;
