import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { getApiData, postApiData } from '../../../fetchApi';
import OnboardingStepOne from './OnboardingSteps/OnboardingStepOne'
import OnboardingStepTwo from './OnboardingSteps/OnboardingStepTwo'
import OnboardingStepThree from './OnboardingSteps/OnboardingStepThree'
import OnboardingStepFour from './OnboardingSteps/OnboardingStepFour'
import ClientOnboardingStepOne from './ClientOnboardingSteps/ClientOnboardingStepOne'
import ClientOnboardingStepTwo from './ClientOnboardingSteps/ClientOnboardingStepTwo'
import ClientOnboardingStepThree from './ClientOnboardingSteps/ClientOnboardingStepThree'
import ClientOnboardingStepFour from './ClientOnboardingSteps/ClientOnboardingStepFour'
import ClientOnboardingStepFive from './ClientOnboardingSteps/ClientOnboardingStepFive'

class Onboarding extends Component {

    constructor(props) {
        super(props)
        this.state = {
            onboardingStep: 0,
            apiData: [],
            guid: "",
            entityId: null,
            contractId: null,
            entityGuid: ""
        }
    }

    componentDidMount = async () => {
        let url = new URL(window.location.href)
        var guid = url.pathname.split('/')[2]
        var entityGuid = url.pathname.split('/')[3]
        var onboardingStep = 0;
        var apiData = await getApiData("GET_USER_BY_GUID", [guid, true])
        if (apiData.length === 0) {
            window.location.replace('/')
        }
        
        else {
            if (apiData[0].reOnboarding === 1) {
                onboardingStep = 1
            }
            this.setState({ apiData: apiData, guid:guid, onboardingStep: onboardingStep, entityGuid: entityGuid})
        }
    }

    trackOnboardingStep = (contractId, entityId) =>
    {
        var onboardingStep = this.state.onboardingStep
        var eId = this.state.entityId
        if (eId !== null && eId !== undefined) {
            entityId = eId
        }
        if (onboardingStep === 4 && window.location.href.includes("client-onboarding")) {
            getApiData("ONBOARD_COMPLETE", [this.state.guid])
            window.location.href = "/"
        }

        if (onboardingStep === 3 && !window.location.href.includes("client-onboarding")) {
            getApiData("ONBOARD_COMPLETE", [this.state.guid])
            window.location.href = "/"
        }

        this.setState({
            onboardingStep: onboardingStep + 1,
            entityId: entityId,
            contractId: contractId
        })

    }

    render() {
        return (
            this.state.apiData.length === 0 ? "" :

            window.location.href.includes("client-onboarding") ? 
                <div className="row">
                    <div className="col-lg-12">
                            {
                                {
                                    0: <ClientOnboardingStepOne guid={this.state.guid} username={this.state.apiData[0].username} trackOnboardingStep={this.trackOnboardingStep}></ClientOnboardingStepOne>,
                                    1: <ClientOnboardingStepTwo entityGuid={this.state.entityGuid} guid={this.state.guid} trackOnboardingStep={this.trackOnboardingStep}></ClientOnboardingStepTwo>,
                                    2: <ClientOnboardingStepThree entityGuid={this.state.entityGuid} guid={this.state.guid} contractId={this.state.contractId} trackOnboardingStep={this.trackOnboardingStep}></ClientOnboardingStepThree>,
                                    3: <ClientOnboardingStepFour  guid={this.state.guid} trackOnboardingStep={this.trackOnboardingStep}></ClientOnboardingStepFour>,
                                    4: <ClientOnboardingStepFive entityId={this.state.entityId} guid={this.state.guid} trackOnboardingStep={this.trackOnboardingStep}></ClientOnboardingStepFive>,
                            }[this.state.onboardingStep]
                        }
                    </div>
                </div>
            
            :

                <div className="row">
                    <div className="col-lg-12">
                    {
                        {
                            0: <OnboardingStepOne guid={this.state.guid} username={this.state.apiData[0].username} trackOnboardingStep={this.trackOnboardingStep}></OnboardingStepOne>,
                            1: <OnboardingStepTwo apiData={this.state.apiData} guid={this.state.guid} trackOnboardingStep={this.trackOnboardingStep}></OnboardingStepTwo>,
                            2: <OnboardingStepThree guid={this.state.guid} contractId={this.state.contractId} apiData={this.state.apiData} trackOnboardingStep={this.trackOnboardingStep}></OnboardingStepThree>,
                            3: <OnboardingStepFour entityGuid={this.state.entityGuid} guid={this.state.guid} trackOnboardingStep={this.trackOnboardingStep}></OnboardingStepFour>,
                        }[this.state.onboardingStep]
                            }
                   </div>
                </div>
        );
    }
}

export default Onboarding;