import React, { Component } from 'react';
import SignaturePad from 'signature_pad';
import AWS from 'aws-sdk'
import config from '../../../Config/config.json';



class SignatureCanvas extends Component {
    constructor(props) {
        super(props);
        this.canvas = React.createRef();
        this.signaturePad = null;
        this.state = {
            signatureImage: null,
        };
    }

    dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }


    componentDidMount() {
        this.signaturePad = new SignaturePad(this.canvas.current);
    }

    handleClearClick = () => {
        this.signaturePad.clear();
        this.setState({ signatureImage: null });
    }

    handleSaveClick = async () => {
        const signatureImage = this.signaturePad.toDataURL()
        const blob = this.dataURItoBlob(signatureImage);
        const S3_BUCKET = config["S3_BUCKET"];
        const REGION = config["Region"];


        AWS.config.update({
            accessKeyId: config["accessKeyId"],
            secretAccessKey: config["secretAccessKey"]
        })


        const myBucket = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        })

        var guid = this.props.guid

        var key = ""

        switch (this.props.signatureType) {
            case "_bankingSignature":
                key = "BankSignatures/" + this.props.entityGuid + "/" + guid + "/bankSignature"
                break;
            case "_contractSignature":
                key = "ContractSignatures/" + this.props.entityGuid + "/" + guid + "/ContractSignature"
                break;
            case "_w4Signature":
                key = "W4Signatures/" + this.props.entityGuid + "/" + guid + "/W4Signatures"
                break;
            case "_signatorySignature":
                key = "SignatorySignature/" + this.props.entityGuid + "/" + guid + "/Signatory"
                break;
        }


        const params = {
            ACL: 'private',
            Body: blob,
            Bucket: S3_BUCKET,
            ContentType: 'image/png',
            Key: key
        };

        await myBucket.putObject(params, (err, data) => {
            if (err) {
                console.log(err);
                return;
            }
        });

        await myBucket.waitFor('objectExists', { Key: params.Key }).promise();

        switch (this.props.signatureType)
        {
            case "_bankingSignature":
                this.props.saveBankingInfo();
                break;
            case "_contractSignature":
                this.props.saveContractSignature();
            case "_w4Signature":
                this.props.handleFederalWithholding();
        }
        
    }

    render() {
        return (
            <>
            <span>By signing below, I authorize Fluid Payroll LLC to blah blah blah </span>
                <div style={{ backgroundColor: '#eee', border: '1px solid black', marginBottom: 40, padding:20 }} >
                <canvas ref={this.canvas} width={300} height={150}></canvas>
                <button className="btn btn-primary" onClick={this.handleClearClick}>Clear</button> &nbsp;&nbsp;
                <button className="btn btn-warning" onClick={this.handleSaveClick}>Save</button>
                {this.state.signatureImage &&
                    <img src={this.state.signatureImage} alt="Signature" />
                }
                </div>
            </>
        );
    }
}

export default SignatureCanvas;