import React, { Component } from 'react';
import MainGridComponent from '../../MainGridComponent'
import PayrollCalculator from './PayrollCalculator'
import { getApiData, postApiData } from '../../../fetchApi'
import Modal from 'react-bootstrap/Modal';

export class PayrollDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedTeamMemberId : 0,
            showTeamMemberModal: false,
            teamMemberInfo: {},
            teamMemberDeductionInfo: [],
            deductions: [],
            selectedDeductionId: 0,
            selectedDeductionName: '',
            stepTracker: 0
        }
    }

    componentDidMount = async () => {
        //var payrollDetails = 
    }

    setSelectedTeamMember = async (teamMemberId) => 
    {
        var deductions = await getApiData("GET_DEDUCTIONS")
        var teamMemberInfo = await getApiData("GET_TEAM_MEMBER_BY_ID", [teamMemberId])
        var teamMemberDeductionInfo = await getApiData("GET_DEDUCTIONS_BY_TEAM_MEMBER_ID", [teamMemberId, sessionStorage.getItem('x-params-2')])
        var existingDeductionIds = new Set(teamMemberDeductionInfo.map(d => d.deductionId));
        deductions = deductions.filter(d => !existingDeductionIds.has(d.id));
        this.setState({ selectedTeamMemberId: teamMemberId, showTeamMemberModal: true, teamMemberInfo, teamMemberDeductionInfo, deductions })
    }

    closeTeamMemberModal = () => {
        this.setState({ selectedTeamMemberId: 0, showTeamMemberModal: false, teamMembmerInfo: {}, teamMemberDeductionInfo: [], contributionArray: [] })
    }

    handleContributionChange = (deductionId, contribution) =>
    {
        var updatedTeamMemberDeductionInfo = this.state.teamMemberDeductionInfo.map(info => {
            if (info.deductionId === deductionId) {
                // Update the teammemberContribution property
                return {
                    ...info,
                    teamMemberContribution: contribution
                };
            }
            return info;
        });

        // Update the state with the new array
        this.setState({ teamMemberDeductionInfo: updatedTeamMemberDeductionInfo });
    }

    saveNewDeductions = async () => {
        await postApiData("SAVE_TEAMMEMBER_CONTRIBUTION", [this.state.teamMemberDeductionInfo])
        this.closeTeamMemberModal()
    }

    removeDeductionFromDictionary = async (deductionId) => {
        var teamMemberDeductionInfo = this.state.teamMemberDeductionInfo
        teamMemberDeductionInfo = teamMemberDeductionInfo.filter(x => x.deductionId !== deductionId)
        this.setState({ teamMemberDeductionInfo })
    }

    addDeductionToDictionary = async () => {
        var teamMemberDeductionInfo = this.state.teamMemberDeductionInfo
        teamMemberDeductionInfo.push({ deductionId: parseInt(this.state.selectedDeductionId), deductionName: this.state.selectedDeductionName, entityId: this.state.teamMemberDeductionInfo[0].entityId, id: 0, isActive: 1, rothIRA: null, teamMemberContribution: '0', teamMemberId: this.state.teamMemberDeductionInfo[0].teammemberId, year: new Date().getFullYear(), ytdPayout: '0' })
        this.setState({ teamMemberDeductionInfo })
    }

    setSelectedDeduction = async (e) => {
        var id = e.target.value
        var name = e.target.options[e.target.selectedIndex].text
        this.setState({selectedDeductionId: id, selectedDeductionName: name})
    }

    nextStep = async () => {
        var stepTracker = this.state.stepTracker + 1
        this.setState({ stepTracker })
    }

    backStep = async () => {
        var stepTracker = this.state.stepTracker - 1
        this.setState({ stepTracker })
    }

    render() {
        return (
            <>
                
                <Modal
                    show={this.state.showTeamMemberModal}
                    onHide={this.closeTeamMemberModal}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.teamMemberInfo.fullName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <select onChange={this.setSelectedDeduction} className="form-control">
                            <option value="">Please select a deduction</option>
                            {this.state.deductions.map((deduction) => ( 
                                <option key={deduction.id} value={deduction.id}>{deduction.deduction}</option>
                            ))}
                        </select> <br />
                        <btn onClick={this.addDeductionToDictionary} className="btn btn-primary">Add Deduction</btn>
                        <div><br /> </div>
                        {this.state.teamMemberDeductionInfo.map((deductionTeam) => (
                            <> <div className=""><b> {deductionTeam.deductionName} &nbsp;</b> <input onChange={(event) => this.handleContributionChange(deductionTeam.deductionId, event.target.value)} style={{ width: 120 }} type="number" min="0.01" step="0.01" value={parseFloat(deductionTeam.teamMemberContribution)} /> <a style={{ cursor: 'pointer' }} onClick={(event) => this.removeDeductionFromDictionary(deductionTeam.deductionId)}><b style={{ color: 'red' }}>X</b></a> </div> <br /> </>
                        ))}
                   
                    </Modal.Body>
                    <Modal.Footer>
                        <btn className="btn btn-secondary" onClick={this.closeTeamMemberModal}>Close</btn>
                        <btn onClick={this.saveNewDeductions} className="btn btn-primary">Save changes</btn>
                    </Modal.Footer>
                </Modal>

                <div style={{ display: "inline-block" }}><a className="btn btn-danger" onClick={this.props.backToPayroll}>Back To Payroll</a> <a className="btn btn-primary" onClick={this.nextStep}>Next Step </a> {this.state.stepTracker > 0 ? <a className="btn btn-warning" onClick={this.backStep}>Previous Step </a> : ""} </div>
                <br /><br />
               
                { 
                    {
                        0: <MainGridComponent key="payroll-details" setSelectedTeamMember={this.setSelectedTeamMember} payrollId={this.props.payrollId} pagetype="PayrollDetails" pageHeader="Salaried Employees"></MainGridComponent>,
                        1: <MainGridComponent key="payroll-details-hourly" setSelectedTeamMember={this.setSelectedTeamMember} payrollId={this.props.payrollId} pagetype="PayrollDetailsHourly" pageHeader="Hourly Employees"></MainGridComponent>,
                        2: <MainGridComponent key="payroll-details-contractors" setSelectedTeamMember={this.setSelectedTeamMember} payrollId={this.props.payrollId} pagetype="PayrollDetailsContractors" pageHeader="Contractors"></MainGridComponent>,
                        3: <PayrollCalculator payrollId={this.props.payrollId} key="payroll-calculator"></PayrollCalculator>
                    }[this.state.stepTracker]
                }
            </>
       );
    }
}

export default PayrollDetails
