import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { NavigationMain } from './components/LoginContent/NavigationMain';
import Onboarding from './components/TeamMemberContent/Onboarding/Onboarding';
import 'bootstrap/dist/css/bootstrap.min.css';

export default class App extends Component {
    static displayName = App.name;
    constructor(props) {
        super(props)
        this.state = {
            GUID: "",
            EntityGUID: ""
        }
    }

    setupSession = (userData) => {
        sessionStorage.setItem("x-params-1", userData.guid)
        sessionStorage.setItem("x-params-2", userData.entityGUID)

        this.setState({ GUID: userData.GUID })
        this.setState({ EntityGUID: userData.entityGUID })
    }

    swapEntities = (entityGuid) => {
        sessionStorage.setItem("x-params-2", entityGuid)
        this.setState({EntityGUID: entityGuid})
    }

    render() {
        return (
            <>
                {window.location.href.includes("onboarding") ? <Onboarding></Onboarding> :
                    sessionStorage.getItem("x-params-1") === null ? <NavigationMain setupSession={this.setupSession} ></NavigationMain> :
                        <Layout swapEntities={this.swapEntities}>
                            <Routes>
                                {AppRoutes.map((route, index) => {
                                    const { element, ...rest } = route;
                                    return <Route key={index} {...rest} element={element} />;
                                })}
                            </Routes>
                        </Layout>}
            </>
        );
    }
}

