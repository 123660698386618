import React, { Component } from 'react';
import MainGridComponent from '../../MainGridComponent'
import { getApiData, postApiData } from '../../../fetchApi'
import Modal from 'react-bootstrap/Modal';
import { post } from 'jquery';

export class PayrollCalculator extends Component {

    constructor(props) {
        super(props)
        this.state = { 
        }
    }

    componentDidMount = async () => {
        
    }

    calculatePayroll = async () => {
        await postApiData("CALCULATE_PAYROLL", [this.props.payrollId])
    }


    render() {
        return (
            <>
                <btn className="btn btn-primary" onClick={this.calculatePayroll}>Calculate payroll</btn>
            </>
       );
    }
}

export default PayrollCalculator
