import React from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf'
import { S3 } from 'aws-sdk';
import SignatureCanvas from '../SignatureCanvas'
import { postApiData } from '../../../../fetchApi';
import '../../../../custom.css'
import config from '../../../../Config/config.json';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PDFViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            numPages: null,
            pageNumber: 1,
            pdfData: null
        };
    } 
    componentDidMount() {
        const s3 = new S3({
            accessKeyId: config["accessKeyId"],
            secretAccessKey: config["secretAccessKey"],
            region: config["Region"]
        });

        const params = {
            Bucket: config["S3_BUCKET"],
            Key: "Contracts/" + this.props.entityGuid + "/" + this.props.guid + "/" + "contract"
           
        };

        s3.getObject(params, (err, data) => {
            if (err) console.log(err, err.stack);
            else {
                const file = new Blob([data.Body], { type: 'application/pdf' });
                this.setState({ pdfData: file });
            }
        });
    }

    saveContractSignature = async () => {
        var contractSigObj = { Guid: this.props.guid, EntityGuid: this.props.entityGuid }
        var response = await postApiData("SAVE_CONTRACT_SIGNATURE", [contractSigObj])
        this.props.trackOnboardingStep(response.contractId, null)
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    goToPreviousPage = () => {
        this.setState(prevState => ({
            pageNumber: prevState.pageNumber - 1,
        }));
    };

    goToNextPage = () => {
        this.setState(prevState => ({
            pageNumber: prevState.pageNumber + 1,
        }));
    };

   

    render() {
        const { pageNumber, numPages, pdfData } = this.state;
        return (
            <>
                <div style={{ width: 700 }} className="onboarding-container">
                    <div style={{ textAlign: 'center' }}> <h2>Contract Signature</h2></div>
                    {pdfData && (
                        <div>
                            <Document file={pdfData} onLoadSuccess={this.onDocumentLoadSuccess}>
                                <Page pageNumber={pageNumber} />
                            </Document>
                            <p>
                                Page {pageNumber} of {numPages}
                            </p>
                        </div>
                    )}
                    <div style={{textAlign:'center'} }>
                        <button className="btn btn-warning"
                            onClick={() =>
                                this.setState(prevState => ({
                                    pageNumber: Math.max(prevState.pageNumber - 1, 1),
                                }))
                            }
                            disabled={pageNumber <= 1}
                        >
                            Previous Page
                        </button> &nbsp;
                        <button className="btn btn-primary"
                            onClick={() =>
                                this.setState(prevState => ({
                                    pageNumber: Math.min(prevState.pageNumber + 1, numPages),
                                }))
                            }
                            disabled={pageNumber >= numPages}
                        >
                            Next Page
                        </button>
                    </div>
                    <div style={{textAlign:'center'} }>
                        <br />
                        <SignatureCanvas entityGuid={this.props.entityGuid} signatureType="_contractSignature" guid={this.props.guid} saveContractSignature={this.saveContractSignature} ></SignatureCanvas>
                    </div>
                </div>
            </>
        );
    }
}

export default PDFViewer;
