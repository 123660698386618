import React, { Component } from 'react';
import { getApiData } from '../../../fetchApi'

class PayrollOnboardingStepThree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payScheduleTypes: [],
            selectedPayScheduleType: '',
            showContractorPayroll: false,
            contractorPayScheduleType: '',
            showPayFrequency: false,
            showContractorPayFrequency: false,
            selectedPayFrequency: '',
            contractorPayFrequency: '',
            isAutoApprove: false,
            arrears: true,
            beforeOrAfterHoliday: 'before',
            semiMonthlyFirstCheckDay: '',
            semiMonthlySecondCheckDay: '',
            contractorSemiMonthlyFirstCheckDay: '',
            contractorSemiMonthlySecondCheckDay: ''
        };
    }

    componentDidMount = async () => {
        // Fetch pay schedule types from API and update state
        var apiData = await getApiData("GET_PAY_SCHEDULES", [])
        var keys = Object.keys(this.props.payrollOnboardingObj)
        var newOnboardingInfo = this.state
        for (var x = 0; x < keys.length; x++) {
            if (newOnboardingInfo[keys[x]] !== undefined) {
                newOnboardingInfo[keys[x]] = this.props.payrollOnboardingObj[keys[x]]
            }
        }
        this.setState({ payScheduleTypes: apiData });
    }

    handlePayScheduleTypeChange = (event) => {
        const selectedType = event.target.value;
        this.setState({
            selectedPayScheduleType: selectedType,
            showPayFrequency: true,
            selectedPayFrequency: '',
        });
    };

    handleContractorCheckboxChange = (event) => {
        this.setState({
            showContractorPayroll: event.target.checked,
            contractorPayScheduleType: '',
            showContractorPayFrequency: false,
            contractorPayFrequency: '',
        });
    };

    handleContractorPayScheduleTypeChange = (event) => {
        this.setState({
            contractorPayScheduleType: event.target.value,
            contractorPayFrequency: '',
        });
    };

    handlePayFrequencyChange = (event) => {
        this.setState({ selectedPayFrequency: event.target.value });
    };

    handleContractorPayFrequencyChange = (event) => {
        this.setState({ contractorPayFrequency: event.target.value });
    };

    handleAutoApprove = (event) => {
        this.setState({ isAutoApprove: event.target.checked })
    }

    handleArrears = (event) => {
        this.setState({ arrears: event.target.checked })
    }

    handleHolidayChange = (event) => {
        this.setState({ beforeOrAfterHoliday: event.target.value })
    }

    handlePayFrequencySemiMonthlyChange = (event) => {
        if (event.target.name === 'firstCheckDate') {
            this.setState({ semiMonthlyFirstCheckDay: event.target.value })
        }
        else {
            this.setState({ semiMonthlySecondCheckDay: event.target.value })
        }
    }

    handleContractorPayFrequencySemiMonthlyChange = (event) => {
        if (event.target.name === 'contractorFirstCheckDate') {
            this.setState({ contractorSemiMonthlyFirstCheckDay: event.target.value })
        }
        else {
            this.setState({ contractorSemiMonthlySecondCheckDay: event.target.value })
        }
    }

    trackPayrollStep = e => {
        var payrollOnboardInfo = this.state
        this.props.trackOnboardingStep(e.target.id, payrollOnboardInfo)
    }

    render() {
        const {
            payScheduleTypes,
            selectedPayScheduleType,
            showContractorPayroll,
            contractorPayScheduleType,
            showPayFrequency,
            selectedPayFrequency,
            contractorPayFrequency,
        } = this.state;

        return (
            <div className="container mt-4">
                <h2>Pay Schedule Setup</h2>
                <div className="mt-3">
                    <label>Select pay schedule type:</label>
                    <select className="form-select" value={selectedPayScheduleType} onChange={this.handlePayScheduleTypeChange}>
                        <option value="">Select pay schedule type</option>
                        {payScheduleTypes.length > 0 && payScheduleTypes.map(type => (
                            <option key={type.id} value={type.id}>{type.paySchedule}</option>
                        ))}
                    </select>
                </div>

                {showPayFrequency && (
                    <div className="mt-3">
                        <label>Pay Frequency:</label>
                        {this.state.selectedPayScheduleType === '2' ? (
                            <>
                                <select
                                    className="form-select"
                                    name="firstCheckDate"
                                    onChange={this.handlePayFrequencySemiMonthlyChange}
                                    value={this.state.semiMonthlyFirstCheckDay}
                                >
                                    <option value="">Select pay frequency</option>
                                    <option value="first">First of the month</option>
                                    {Array.from({ length: 25 }, (_, index) => (
                                        <option>
                                            {index + 2}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    className="form-select mt-2"
                                    name="secondCheckDate"
                                    onChange={this.handlePayFrequencySemiMonthlyChange}
                                    value={this.state.semiMonthlySecondCheckDay}
                                >
                                    <option value="">Select pay frequency</option>
                                    <option value="last">Last of the month</option>
                                    {Array.from({ length: 28 }, (_, index) => (
                                        <option
                                            key={index + 4}
                                            value={index + 4}
                                            
                                        >
                                            {index + 4}
                                        </option>
                                    ))}
                                </select>
                            </>
                        ) : (
                            <select
                                className="form-select"
                                onChange={this.handlePayFrequencyChange}
                                value={selectedPayFrequency}
                            >
                                <option value="">Select pay frequency</option>
                                {selectedPayScheduleType === '1' && (
                                    <>
                                        <option value="first">First of the month</option>
                                        <option value="last">Last day of the month</option>
                                    </>
                                )}
                                {(selectedPayScheduleType === '3' || selectedPayScheduleType === '5') && (
                                    <>
                                        <option value="monday">Monday</option>
                                        <option value="tuesday">Tuesday</option>
                                        <option value="wednesday">Wednesday</option>
                                        <option value="thursday">Thursday</option>
                                        <option value="friday">Friday</option>
                                    </>
                                )}
                            </select>
                        )}
                    </div>
                )}

                <div className="mt-3">
                    <div className="form-check">
                        <input type="checkbox" value={this.state.isAutoApprove} checked={this.state.isAutoApprove} onClick={this.handleAutoApprove} className="form-check-input" id="autoApprove" />
                       Auto Approve Payroll
                    </div>
                </div>

                <div className="mt-3">
                    <div className="form-check">
                        <input type="checkbox" value={this.state.arrears} checked={this.state.arrears} onClick={this.handleArrears} className="form-check-input" id="arrears" />
                       Will you be running payroll in arrears? <b>Note: Most payrolls are paid in arrears</b>
                    </div>
                </div>

                <div className="mt-3">
                    <div className="form-check">
                        
                        <label className="form-check-label" htmlFor="weekendOrHoliday">Do you prefer to make payments prior to or following a scheduled pay date that falls on a non-business day?</label>
                        <select value={this.state.beforeOrAfterHoliday} onChange={this.handleHolidayChange} className="form-control" id="weekendOrHoliday">
                            <option value="before">Before</option>
                            <option value="after">After</option>
                        </select>
                    </div>
                </div>

                <div className="mt-3">
                    <div className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="contractors"
                            onChange={this.handleContractorCheckboxChange}
                            checked={this.state.showContractorPayroll}
                        />
                       Do you also need to pay Contractors? *<b>NOTE: </b>Contractor payrolls cannot be auto approved*
                    </div>
                </div>

                {showContractorPayroll && (
                    <div className="mt-3">
                        <label>Select contractor pay schedule type:</label>
                        <select
                            className="form-select"
                            value={contractorPayScheduleType}
                            onChange={this.handleContractorPayScheduleTypeChange}
                        >
                            <option value="">Select pay schedule type</option>
                            {payScheduleTypes.length > 0 && payScheduleTypes.map(type => (
                                <option key={type.id} value={type.id}>{type.paySchedule}</option>
                            ))}
                        </select>
                    </div>
                )}

                {showContractorPayroll && contractorPayScheduleType && (
                    <div className="mt-3">
                        <label>Pay Frequency:</label>
                        {contractorPayScheduleType === '2' ? (
                            <>
                                <select
                                    className="form-select"
                                    name="contractorFirstCheckDate"
                                    onChange={this.handleContractorPayFrequencySemiMonthlyChange}
                                    value={this.state.contractorSemiMonthlyFirstCheckDay}
                                >
                                    <option value="">Select pay frequency</option>
                                    <option value="first">First of the month</option>
                                    {Array.from({ length: 25 }, (_, index) => (
                                        <option key={index + 2} value={index + 2}>
                                            {index + 2}
                                        </option>
                                    ))}
                                </select>

                                <select
                                    className="form-select mt-2"
                                    onChange={this.handleContractorPayFrequencySemiMonthlyChange}
                                    name="contractorSecondCheckDate"
                                    value={this.state.contractorSemiMonthlySecondCheckDay}
                                >
                                    <option value="">Select pay frequency</option>
                                    <option value="last">Last of the month</option>
                                    {Array.from({ length: 28 }, (_, index) => (
                                        <option
                                            key={index + 4}
                                            value={index + 4}
                                        >
                                            {index + 4}
                                        </option>
                                    ))}
                                </select>

                            </>
                        ) : (
                            <select
                                className="form-select"
                                onChange={this.handleContractorPayFrequencyChange}
                                value={contractorPayFrequency}
                            >
                                <option value="">Select pay frequency</option>
                                {contractorPayScheduleType === '1' && (
                                    <>
                                        <option value="first">First of the month</option>
                                        <option value="last">Last day of the month</option>
                                    </>
                                )}
                                {contractorPayScheduleType === '3' && (
                                    <>
                                        <option value="monday">Monday</option>
                                        <option value="tuesday">Tuesday</option>
                                        <option value="wednesday">Wednesday</option>
                                        <option value="thursday">Thursday</option>
                                        <option value="friday">Friday</option>
                                    </>
                                )}
                            </select>
                        )}
                    </div>
                )}

                <br />
                <div className="row">
                    <div className="col-lg-2"><button id="continue" onClick={this.trackPayrollStep} className="btn btn-primary">Continue</button></div>
                    <div className="col-lg-2"><button id="back" className="btn btn-warning" onClick={this.trackPayrollStep}>Back</button></div>
                    <div className="col-lg-2"><button onClick={this.props.cancelOnboarding} className="btn btn-danger">Cancel</button></div>
                </div>
            </div>
        );
    }
}

export default PayrollOnboardingStepThree;