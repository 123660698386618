import React, { Component } from 'react';
import { postApiData } from '../../../../fetchApi';
import SignatureCanvas from '../SignatureCanvas'
import { CustomInput } from 'reactstrap';

class OnboardingStepFour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExempt: false,
            showConfirmation: false,
            selectedFilingStatus: '',
            singleOrMarriedFilingSeparately: false,
            claimedDeductions: 0,
            claimedDependants: 0,
            additionalIncome: 0,
            isMultipleJobs: ""

        };
    }

    handleExemptChange = (event) => {
        this.setState({
            isExempt: event.target.value === "yes"
        });
    }


    singleOrMarriedFilingSeparatelyChange = (event) => {
        this.setState({
            singleOrMarriedFilingSeparately: event.target.checked ? true : false
        });
    }

    handleFilingStatusChange = (event) => {
        this.setState({
            selectedFilingStatus: event.target.value
        });
    }

    handleClaimedDependants = (event) => {
        this.setState({
            claimedDependants: event.target.value
        });
    }

    handleAdditionalIncome = (event) => {
        this.setState({
            additionalIncome: event.target.value
        });
    }

    handleClaimedDeductions = (event) => {
        this.setState({
            claimedDeductions: event.target.value
        });
    }

    handleConfirmation = () => {
        //TODO handle confirmation logic here
    }

    handleFederalWithholding = async () => {

        var federalWithholdingObj =
            {
                filingType: this.state.selectedFilingStatus,
                claimedDeductions: this.state.claimedDeductions,
                claimedDependants: this.state.claimedDependants,
                additionalIncome: this.state.additionalIncome,
                guid: this.props.guid,
                isMultipleJobs: this.state.isMultipleJobs,
                entityGuid:  this.props.entityGuid
            }
        await postApiData("SAVE_FEDERAL_WITHHOLDING", [federalWithholdingObj])
        this.props.trackOnboardingStep()
    }

    setMultipleJobs = (e) => {
        this.setState({ isMultipleJobs: e.target.value === "Yes" })
    }

    renderConfirmationSection() {
        return (
            <div>
                <p>Are you sure you are exempt from federal withholding?</p>
                <p>Most people are not exempt from federal tax withholding.</p>
                <button className="btn btn-danger" onClick={this.handleConfirmation}>Yes, I am sure</button>
            </div>
        );
    }

    renderFilingStatusSection() {
        return (
            <div>
            <br />
                <label>Please select your filing status:</label>
               
                    <input
                        type="radio"
                        name="filingStatus"
                        value="single"
                        checked={this.state.selectedFilingStatus === 'single'}
                        onChange={this.handleFilingStatusChange}
                    />
                    Single
                    <br />
                    <input
                        type="radio"
                        name="filingStatus"
                        value="marriedFilingSeparately"
                        checked={this.state.selectedFilingStatus === 'marriedFilingSeparately'}
                        onChange={this.handleFilingStatusChange}
                    />
                    Married Filing Separately
                <br />
                    <input
                        type="radio"
                        name="filingStatus"
                        value="filingMarriedJointly"
                        checked={this.state.selectedFilingStatus === 'filingMarriedJointly'}
                        onChange={this.handleFilingStatusChange}
                    />
                    Filing Married Jointly
                <br />
                    <input
                        type="radio"
                        name="filingStatus"
                        value="headOfHousehold"
                        checked={this.state.selectedFilingStatus === 'headOfHousehold'}
                        onChange={this.handleFilingStatusChange}
                    />
                    Head of Household
               
            </div>
        );
    }

    render() {
        return (
            <>
                <div style={{ width: 750, margin: "0 auto" }} className="row">
                    <div style={{ textAlign: 'center' }}><img style={{ width: 300 }} src="/StaticHTMLContent/images/2.png"></img></div>
                    <br />
                    <h2 style={{ textAlign: 'center' }}>W4 Information</h2>
                    <br />
                    <div className="col-lg-5">
                       
                     
                        <label>
                            <input
                                type="radio"
                                name="exemptStatus"
                                value="yes"
                                checked={this.state.isExempt}
                                onChange={this.handleExemptChange}
                            />
                            Yes, I am exempt from federal withholding
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="exemptStatus"
                                value="no"
                                checked={!this.state.isExempt}
                                onChange={this.handleExemptChange}
                            />
                            No, I am not exempt from federal withholding
                        </label>
                        {this.state.isExempt ? this.renderConfirmationSection() : null}
                        {this.state.isExempt ? null : this.renderFilingStatusSection()}
                    </div>
                
                <div className="col-lg-1"></div>
                <div className="col-lg-6">
                {
                        {
                        //TODO Revisit this, prolly dont need the switch statement
                            "single": 
                        
                               <div>
                                <div style={{ marginBottom: 20 }}>
                                <label>Do you have multiple jobs?</label> <br />
                                    <input
                                        type="radio"
                                        checked={this.state.isMultipleJobs}
                                        value="Yes"
                                        onChange={this.setMultipleJobs}
                                    />
                                    Yes
                                    <input
                                        type="radio"
                                        checked={!this.state.isMultipleJobs}
                                        value="No"
                                        onChange={this.setMultipleJobs}
                                    />
                                    No
                                </div>
                                  
                                <div><b>Will you claim deductions other than the standard deductions to reduce your withholding? (Line 4b)</b></div><br />
                                <div><input onChange={this.handleClaimedDeductions} value={this.state.claimedDeductions}></input></div><br />
                                <div><b>Do you have any other income to declare? (Line 4a)</b></div><br />
                                <div><input onChange={this.handleAdditionalIncome} value={this.state.additionalIncome}></input></div><br />
                                <div><b>Would you like to claim any dependants? ($2000 per dependant under 17, $500 per any other dependants (Line 3)</b></div><br />
                                <div><input onChange={this.handleClaimedDependants} value={this.state.claimedDependants}></input></div><br />
                                </div>,

                            "marriedFilingSeparately":

                                <div>
                                    <div style={{marginBottom:20}}>
                                        <label>Do you have multiple jobs?</label><br />
                                        <input
                                            type="radio"
                                            checked={this.state.isMultipleJobs}
                                            value="Yes"
                                            onChange={this.setMultipleJobs}
                                        />
                                        Yes
                                        <input
                                            type="radio"
                                            checked={!this.state.isMultipleJobs}
                                            value="No"
                                            onChange={this.setMultipleJobs}
                                        />
                                        No
                                       
                                    </div>
                                   
                                    <div><b>Will you claim deductions other than the standard deductions to reduce your withholding? (Line 4b)</b></div><br />
                                    <div><input onChange={this.handleClaimedDeductions} value={this.state.claimedDeductions}></input></div><br />
                                    <div><b>Do you have any other income to declare? (Line 4a)</b></div><br />
                                    <div><input onChange={this.handleAdditionalIncome} value={this.state.additionalIncome}></input></div><br />
                                    <div><b>Would you like to claim any dependants? ($2000 per dependant under 17, $500 per any other dependants (Line 3)</b></div><br />
                                    <div><input onChange={this.handleClaimedDependants} value={this.state.claimedDependants}></input></div><br />
                                </div>,


                        "filingMarriedJointly":"",
                        "headOfHousehold": ""
                    }[this.state.selectedFilingStatus]
                    }
            </div>
                    <div style={{ margin: "0 auto", width: 500, marginTop: 20 }}> <SignatureCanvas handleFederalWithholding={this.handleFederalWithholding} entityGuid={this.props.entityGuid} signatureType="_w4Signature" guid={this.props.guid} ></SignatureCanvas></div> 
                </div>
                </>
        );
    }
}

export default OnboardingStepFour