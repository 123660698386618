import React, { Component } from 'react';
import { getApiData } from '../../../fetchApi';
import Select from 'react-select'

class HiringStepThree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            manager: [],
            jobTitle: '',
            startDate: '',
            directReports: [],
            selectedDirectReports: [],
            departments: [],
            selectedDepartments: []
        };

        this.handleManagerChange = this.handleManagerChange.bind(this);
        this.handleJobTitleChange = this.handleJobTitleChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleDirectReportsChange = this.handleDirectReportsChange.bind(this);
        this.handleDepartmentChange = this.handleDepartmentChange.bind(this);
    }

    componentDidMount = async () => {
        // Fetch team members data for manager and direct reports autocomplete inputs
        var departmentData = await getApiData("GET_DEPARTMENTS", [sessionStorage.getItem('x-params-2')])
        var directReportsData = await getApiData("GET_TEAM_MEMBER_LIST", [sessionStorage.getItem('x-params-2')])
        var directReportsOptions = []
        var departmentOptions = []
        directReportsOptions = directReportsData.map(x => { return { value: x.id, label: x.fullName /*+ ", " + x.teamMemberDetails.title*/ } })
        departmentOptions = departmentData.map(x => { return { value: x.id, label: x.departmentName } })

        var keys = Object.keys(this.props.newHireObj)
        var newHireInfo = this.state
        for (var x = 0; x < keys.length; x++) {
            if (newHireInfo[keys[x]] !== undefined) {
                newHireInfo[keys[x]] = this.props.newHireObj[keys[x]]
            }
        }
        
        this.setState({ departments: departmentOptions, directReports: directReportsOptions });
    }

    handleManagerChange = (selectedOptions) => {
        this.setState({ manager: selectedOptions });
    }

    handleJobTitleChange(event) {
        this.setState({ jobTitle: event.target.value });
    }

    handleStartDateChange(event) {
        this.setState({ startDate: event.target.value });
    }

    handleDirectReportsChange = (selectedOptions) => {
        this.setState({ selectedDirectReports: selectedOptions });
    }

    handleDepartmentChange = (selectedOptions) => {
        this.setState({ selectedDepartments: selectedOptions });
    }


    trackHiringStep = e => {
        var hiringInfo = this.state
        this.props.trackHiringStep(e.target.id, hiringInfo)
    }

    render() {
        return (
            <div>
                <h4>Role Details</h4>
                <form>
                    <div className="row">
                        <div className="col-lg-12"><b>Manager:</b>
                        
                            <Select
                                name="manager"
                                options={this.state.directReports}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleManagerChange}
                                value={this.state.manager}
                            />
                        </div>
                    </div>
                   <br />
                    <div className="row">
                        <div className="col-lg-12"><b>Job Title:</b> <br />
                            <input type="text" className="form-control" defaultValue={this.state.jobTitle} onChange={this.handleJobTitleChange} /></div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-lg-12"><b> Start Date:</b> <br />
                            <input className="form-control" type="date" defaultValue={this.state.startDate} onChange={this.handleStartDateChange} /></div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-lg-12"><b>Who will be the direct report(s):</b>
                            <Select
                                isMulti
                                name="direct-reports"
                                options={this.state.directReports}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleDirectReportsChange}
                                value={this.state.selectedDirectReports}
                            />
                        </div>
                    </div>
                    <br />    
                </form>
                <h4>Additional Role Details</h4>
                <form>
                    <div className="row">
                        <div className="col-lg-12"><b> Department:</b> <br />
                            <Select
                                isMulti
                                name="departments"
                                options={this.state.departments}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleDepartmentChange}
                                value={this.state.selectedDepartments}
                            />
                        </div>
                    </div>
                </form>
                <br />
                <div className="row">
                    <div className="col-lg-2"><button id="continue" onClick={this.trackHiringStep} className="btn btn-primary">Continue</button></div>
                    <div className="col-lg-2"><button id="back" className="btn btn-warning" onClick={this.trackHiringStep}>Back</button></div>
                    <div className="col-lg-2"><button onClick={this.props.cancelHiring} className="btn btn-danger">Cancel</button></div>
                </div>
            </div>
        );
    }
}

export default HiringStepThree
