import React, { Component } from 'react';
import { postApiFormData } from '../../../fetchApi';
import ManualOnboarding from '../Onboarding/ManualOnboarding'

class HiringComplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowTemplate: false,
            subject: "",
            message: "",
            signature: "",
            documents: [],
            isManualOnboard: false,
            chosenFiles: []
        };
    }


    showHiringTemplates = () => 
    {
        this.setState({isShowTemplate: true})
    }

    handleSubjectChange = (e) =>
    {
        this.setState({subject: e.target.value})
    }

    handleMessageChange = (e) =>
    {
        this.setState({message: e.target.value})
    }

    handleSignatureChange = (e) =>
    {
        this.setState({signature: e.target.value})
    }

    submitNewHireForm = async (event) =>
    {
        var formData = new FormData()
        formData.append("subject", this.state.subject)
        formData.append("message", this.state.message)
        formData.append("signature", this.state.signature)
        formData.append("guid", this.props.guid.guid)
        formData.append("email", this.props.newHireObj.email)
        for (var x = 0; x < this.state.chosenFiles.length; x++) {
            formData.append("files", this.state.chosenFiles[x])
        }
        
        var test = await postApiFormData("SEND_OFFER_LETTER_EMAIL", formData)


        this.props.cancelHiring()
    }

    handleFileEvent = (e) => {
        const chosenFiles = e.target.files
        this.setState({ chosenFiles: chosenFiles })
    }

    setManualOnboard = () => {
        this.setState({ isManualOnboard: true })
    }

    cancelManualOnboard = () => {
        this.setState({ isManualOnboard: false })
    }

    render() {
        return (
            <>
                <div className="row">If you would prefer to manually onboard your new hire onsite instead, select "Manual Onboarding". Otherwise, fill out the information below.</div>
                <div className="row"><a onClick="this.setManualOnboard" className="btn btn-primary">Manual Onboarding</a></div>
                {this.state.isManualOnboard ?
                    <ManualOnboarding cancelManualOnboard="this.cancelManualOnboard"></ManualOnboarding>
                    :
                    <>
                        <form onSubmit={this.submitNewHireForm}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <b>Please upload all documents that you wish to send to the new hire (PDF FORMAT)</b>
                                </div>
                            <br />
                                <div className="col-lg-12">
                                    <input id="fileUpload" accept='application/pdf' onChange={this.handleFileEvent} type="file" multiple></input>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                    <div className="col-lg-12">
                                        <span>Please customize your email below:</span> <br /> <br />
                                        <b>Email Subject</b> <br />
                                        <input name="subject" type="text" className='form-control' onChange={this.handleSubjectChange} defaultValue={this.state.subject}></input> <br />
                                        <span><b>Enter a message</b></span> <br />
                                        <textarea name="personalmsg" className="form-control" onChange={this.handleMessageChange}></textarea> <br /> <br />
                                        <b>Insert your email signature below</b> <br />
                                        <textarea name="signature" className="form-control"  onChange={this.handleSignatureChange}></textarea>
                                    </div>
                            </div>
                                <br />
                            <div className='row'>
                                <div>
                                    <button className="btn btn-primary" type="submit">Send Email</button>
                                </div>
                            </div>
                        </form>
                    </>
                }
            </>
        );
    }
}

export default HiringComplete;