import React, { Component } from 'react';
import { postApiData } from '../../../../fetchApi';

class ClientOnboardingStepOne extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newUsername: '',
            newPassword: '',
            confirmPassword: '',
            formError: '',
            firstName: '',
            lastName: ''
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        if (this.state.newPassword !== this.state.confirmPassword) {
            this.setState({
                formError: 'Passwords do not match.'
            });
            return;
        }
        var updatePasswordObj =
        {
            newPassword: this.state.newPassword,
            newUsername: this.state.newUsername,
            guid: this.props.guid,
            firstName: this.state.firstName,
            lastName: this.state.lastName
        }
        await postApiData("UPDATE_OWNER_ACCOUNT_INFO", [updatePasswordObj])
        this.props.trackOnboardingStep()
    }

    render() {
        return (
            <div className="onboarding-container">
                <div style={{textAlign:'center'}}><img style={{ width: 300 }} src="/StaticHTMLContent/images/2.png"></img></div>
                <br />
                <h2 style={{ textAlign: 'center' }}>Account Information</h2>
                <br />
                <form onSubmit={this.handleSubmit}>
                    <div>
                        <label>
                            <b>Enter Username:</b>
                            <input className="form-control" name="newUsername" value={this.state.newUsername} onChange={this.handleInputChange} required type="text" />
                        </label>
                    </div>
                    <br />
                    <div>
                        <label>
                             <b>Enter New Password:</b>
                             <input className="form-control" required type="password" name="newPassword" value={this.state.newPassword} onChange={this.handleInputChange} />
                        </label>
                    </div>
                    <br />
                    <div>
                        <label>
                            <b>Confirm New Password:</b>
                            <input className="form-control" required type="password" name="confirmPassword" value={this.state.confirmPassword} onChange={this.handleInputChange} />
                        </label>
                    </div>
                    <br />
                    <div>
                        <label>
                           <b>Legal First Name:</b>
                            <input className="form-control" required type="text" name="firstName" value={this.state.firstName} onChange={this.handleInputChange} />
                        </label>
                    </div>
                    <br />
                    <div>
                    <label>
                            <b>Legal Last Name:</b>
                        <input className="form-control" required type="text" name="lastName" value={this.state.lastName} onChange={this.handleInputChange} />
                        </label>
                    </div>
                    <br />
                    {this.state.formError && <><p className="error">{this.state.formError}</p><br /></>}
                    <div style={{textAlign:'center'} }><button style={{ textAlign: 'center' }} className="btn btn-primary" type="submit">Save</button></div>
                </form>
            </div>
        );
    }
}

export default ClientOnboardingStepOne;
