import React, { Component } from 'react';
import HiringStepOne from '../TeamMemberContent/HiringSteps/HiringStepOne'
import HiringStepTwo from '../TeamMemberContent/HiringSteps/HiringStepTwo'
import HiringStepThree from '../TeamMemberContent/HiringSteps/HiringStepThree'
import HiringStepFour from '../TeamMemberContent/HiringSteps/HiringStepFour'
import HiringComplete from '../TeamMemberContent/HiringSteps/HiringComplete'
import { postApiData } from '../../fetchApi';

class NewHiring extends Component {

    constructor(props) {
        super(props)
        this.state = {
            hiringStep: 0,
            guid: "",
            newHireObj: {
                candidateOption: '',
                firstName: '',
                lastName: '',
                email: '',
                selectedWorkLocations: [],
                selectedEmploymentType: '',
                isExempt: false,
                manager: [],
                jobTitle: '',
                startDate: '',
                directReports: [],
                selectedDirectReports: [],
                departments: [],
                selectedDepartments: [],
                salary: '',
                payType: '',
                upFrontPayments: '',
                isHire: false,
                isBackGroundCheck: false,
                isTrackingSystem: false,
                isPreviousEmployee: false,
                isManual: false,
            }
        }
    }

    trackHiringStep = async (id, data) => {
        var guid = ""
        var hiringStep = this.state.hiringStep
        if (id === "continue") {
            hiringStep = hiringStep + 1
        }
        else if (id === "back") {
            hiringStep = hiringStep - 1
        }

        var hiringObj = this.state.newHireObj
        var keys = Object.keys(data)

        for (var x = 0; x < keys.length; x++) {
            hiringObj[keys[x]] = data[keys[x]]
        }

        if (id === "save") {
            var cleanHireObj = {
                firstName: this.state.newHireObj.firstName,
                lastName: this.state.newHireObj.lastName,
                email: this.state.newHireObj.email,
                selectedWorkLocations: this.state.newHireObj.selectedWorkLocations.map(x => { return x.value + " " }),
                selectedEmploymentType: this.state.newHireObj.selectedEmploymentType,
                isExempt: this.state.newHireObj.isExempt.toString(),
                manager: this.state.newHireObj.manager.value.toString(),
                jobTitle: this.state.newHireObj.jobTitle,
                startDate: this.state.newHireObj.startDate,
                selectedDirectReports: this.state.newHireObj.selectedDirectReports.map(x => { return x.value + " " }),
                selectedDepartments: this.state.newHireObj.selectedDepartments.map(x => { return x.value + " " }),
                salary: this.state.newHireObj.salary,
                payType: this.state.newHireObj.payType,
                upFrontPayments: this.state.newHireObj.upFrontPayments,
                entityGUID: [sessionStorage.getItem("x-params-2")].toString(),
                hiringType: "Single"
            };

            cleanHireObj.selectedWorkLocations = cleanHireObj.selectedWorkLocations.join(",")
            cleanHireObj.selectedDirectReports = cleanHireObj.selectedDirectReports.join(",")
            cleanHireObj.selectedDepartments = cleanHireObj.selectedDepartments.join(",")
            guid = await postApiData("HIRE_NEW_TEAM_MEMBER", [cleanHireObj])
            hiringStep = hiringStep + 1;
        }
        this.setState({ hiringStep: hiringStep, newHireObj: hiringObj, guid: guid })
    }


    render() {
        return (
              <div className="col-lg-8">
                {
                    {
                        0: <HiringStepOne newHireObj={this.state.newHireObj} cancelHiring={this.props.cancelHiring} trackHiringStep={this.trackHiringStep}></HiringStepOne>,
                        1: <HiringStepTwo newHireObj={this.state.newHireObj} cancelHiring={this.props.cancelHiring} trackHiringStep={this.trackHiringStep}></HiringStepTwo>,
                        2: <HiringStepThree newHireObj={this.state.newHireObj} cancelHiring={this.props.cancelHiring} trackHiringStep={this.trackHiringStep}></HiringStepThree>,
                        3: <HiringStepFour newHireObj={this.state.newHireObj} cancelHiring={this.props.cancelHiring} trackHiringStep={this.trackHiringStep}></HiringStepFour>,
                        4: <HiringComplete guid={this.state.guid} newHireObj={this.state.newHireObj} cancelHiring={this.props.cancelHiring}></HiringComplete>
                    }[this.state.hiringStep]
                }
              </div>
            );
    }
}

export default NewHiring;