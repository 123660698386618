import React, { Component } from 'react';

class PayrollOnboardingStepOne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasRunPayroll: null,
            previousProvider: '',
            username: '',
            password: '',
            additionalInfo: '',
            sellsPharmaceuticals: null,
            partOfCannabisIndustries: null,
            earnsRevenueFromGaming: null,
            ownsStripClubsOrPornography: null,
            businessActivity: '',
        };

    }

    componentDidMount = async () => {
        var keys = Object.keys(this.props.payrollOnboardingObj)
        var newOnboardingInfo = this.state
        for (var x = 0; x < keys.length; x++) {
            if (newOnboardingInfo[keys[x]] !== undefined) {
                newOnboardingInfo[keys[x]] = this.props.payrollOnboardingObj[keys[x]]
            }
        }
        this.setState({});
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    trackPayrollStep = e => {
        var payrollOnboardInfo = this.state
        this.props.trackOnboardingStep(e.target.id, payrollOnboardInfo)
    }


    render() {
        const {
            hasRunPayroll,
            previousProvider,
            username,
            password,
            additionalInfo,
            sellsPharmaceuticals,
            partOfCannabisIndustries,
            earnsRevenueFromGaming,
            ownsStripClubsOrPornography,
            businessActivity,
        } = this.state;

        return (
            <div className="container">
                <form>
                    <div className="form-group">
                        <label>Have you ever run payroll with another provider?</label>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="hasRunPayroll"
                                value="yes"
                                checked={hasRunPayroll === 'yes'}
                                onChange={this.handleChange}
                            />
                           Yes
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="hasRunPayroll"
                                value="no"
                                checked={hasRunPayroll === 'no'}
                                onChange={this.handleChange}
                            />
                           No
                        </div>
                    </div>

                    {hasRunPayroll === 'yes' && (
                        <div>
                            <div className="form-group">
                                <label>Select previous provider:</label>
                                <select
                                    className="form-control"
                                    value={previousProvider}
                                    onChange={this.handleChange}
                                    name="previousProvider"
                                >
                                    <option value="">Select Provider</option>
                                    <option value="ADP Resource">ADP Resource</option>
                                    <option value="ADP Run">ADP Run</option>
                                    <option value="Gusto">Gusto</option>
                                    <option value="Intuit Online Payroll">Intuit Online Payroll</option>
                                    <option value="Intuit">Intuit</option>
                                    <option value="Isolved">Isolved</option>
                                </select>
                            </div>

                            <div>
                                <p>
                                    We need your previous payroll information in order to calculate your taxes
                                    accurately. We will do this for you on your behalf with the provided credentials.
                                </p>
                                <div className="form-group">
                                    <label>Username:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={username}
                                        name="username"
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Password:</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        value={password}
                                        name="password"
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Additional Information:</label>
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        name="additionalInfo"
                                        value={additionalInfo}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="form-group">
                        <label>Does your company or companies under the same ownership sell or market any products that are pharmaceuticals?</label>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="sellsPharmaceuticals"
                                value="yes"
                                checked={sellsPharmaceuticals === 'yes'}
                                onChange={this.handleChange}
                            />
                            Yes
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="sellsPharmaceuticals"
                                value="no"
                                checked={sellsPharmaceuticals === 'no'}
                                onChange={this.handleChange}
                            />
                            No
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Does your company or companies under the same ownership take part in Cannabis Industries?</label>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="partOfCannabisIndustries"
                                value="yes"
                                checked={partOfCannabisIndustries === 'yes'}
                                onChange={this.handleChange}
                            />
                            Yes
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="partOfCannabisIndustries"
                                value="no"
                                checked={partOfCannabisIndustries === 'no'}
                                onChange={this.handleChange}
                            />
                           No
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Does your company or companies under the same ownership earn revenue from gaming or sports betting, or gambling of any kind. In addition, do they operate or own any casinos or gambling/sports betting facilities?</label>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="earnsRevenueFromGaming"
                                value="yes"
                                checked={earnsRevenueFromGaming === 'yes'}
                                onChange={this.handleChange}
                            />
                           Yes
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="earnsRevenueFromGaming"
                                value="no"
                                checked={earnsRevenueFromGaming === 'no'}
                                onChange={this.handleChange}
                            />
                            No
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Does your company or companies under the same ownership own or operate any strip clubs, or make/distribute pornography?</label>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="ownsStripClubsOrPornography"
                                value="yes"
                                checked={ownsStripClubsOrPornography === 'yes'}
                                onChange={this.handleChange}
                            />
                           Yes
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="ownsStripClubsOrPornography"
                                value="no"
                                checked={ownsStripClubsOrPornography === 'no'}
                                onChange={this.handleChange}
                            />
                            No
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Business Activity:</label>
                        <select
                            className="form-control"
                            value={businessActivity}
                            name="businessActivity"
                            onChange={this.handleChange}
                        >
                            <option value="">Select Business Activity</option>
                            <option value="Finance">Finance</option>
                        </select>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-lg-2"><button id="continue" onClick={this.trackPayrollStep} className="btn btn-primary">Continue</button></div>
                        <div className="col-lg-2"><button id="back" className="btn btn-warning" onClick={this.trackPayrollStep}>Back</button></div>
                        <div className="col-lg-2"><button onClick={this.props.cancelOnboarding} className="btn btn-danger">Cancel</button></div>
                    </div>
                </form>
            </div>
        );
    }
}

export default PayrollOnboardingStepOne;